















































import { ApiGetCustomerDto, ApiGetCustomerPersonDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";
import { VSwitch, VTextField } from "vuetify/lib";
import { AddressBookRouteNames } from "@/shared/enums/RouteNames/addressBookRouteNames.enum";

export default defineComponent({
  name: "ContactsPeoplePage",
  components: {
    BaseTableFiltered,
  },
  setup() {
    const restrictedAccessApi = useRestrictedAccessApi();
    const users = ref<ApiGetCustomerPersonDto[] | ApiGetCustomerDto[]>();

    const getUsers = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        users.value = (await restrictedAccessApi.getCustomerPersons()).data;
      });
    };

    const toCustomerPersonPage = (id: number) => ({
      name: AddressBookRouteNames.CustomerPerson,
      params: { id: id.toString() },
    });

    const filter = computed(() => [
      {
        component: VTextField,
        value: "customer",
        default: "",
        attrs: {
          label: "Navn",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: Record<string, string>, model: string) => isValueIncluded(getFullName(value), model),
      },
      {
        component: VTextField,
        value: "mobileNumber",
        default: "",
        attrs: {
          label: "Mobilnummer",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: string, model: string) => isValueIncluded(value, model),
      },
      {
        component: VTextField,
        value: "email",
        default: "",
        attrs: {
          label: "E-post",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: string, model: string) => isValueIncluded(value, model),
      },
      {
        component: VSwitch,
        value: "isActive",
        default: true,
        staticClass: "mx-3",
        attrs: {
          inset: true,
          label: "Vis inaktive",
        },
        apply: (value: boolean, model: boolean) => model || value,
      },
    ]);

    onMounted(async () => {
      await getUsers();
    });

    return {
      headers,
      users,
      filter,
      toCustomerPersonPage,
    };
  },
});

const getFullName = (value: Record<string, string>) => `${value.firstName} ${value.lastName}`;

const isValueIncluded = (value: string, model: string) => {
  if (!model || !value) {
    return;
  }
  return value.toLowerCase().includes(model.toLowerCase());
};

const headers = [
  {
    text: "Fornavn",
    value: "customer.firstName",
  },
  {
    text: "Etternavn",
    value: "customer.lastName",
  },
  {
    text: "E-post",
    value: "email",
  },
  {
    text: "Telefon",
    value: "mobileNumber",
  },
  {
    text: "Aktiv",
    value: "isActive",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
];
